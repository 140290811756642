// SEARCH

jQuery(document).ready(function($){

  $('.c-search-btn').click(function() {
    $(this).toggleClass('active');
    $('.c-search-drop').toggleClass('active');
    $('.c-search-drop .search-box').focus();
  });

  $('.c-search--close').click(function() {
    $('.c-search-btn').removeClass('active');
    $('.c-search-drop').removeClass('active');
  });

});
