// SEARCH

jQuery(document).ready(function($){

  $('.c-menu-btn').click(function() {

    $('.c-menu-mobile').toggleClass('active');
  });


});
